.footer {
  text-align: center;
  font-size: 10px;
  gap: 5px;
  display: flex;
  flex-direction: column;
  margin-top: 1em;
  margin-bottom: 2.5em;
  width: 100%;
  font-family: "Roboto Mono";
}
