#title-section {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.title-third {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#title-center-third {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1em;
  font-family: "Roboto Mono";
}

/* ASCII Head Animation */
#profile-animation-canvas {
  height: 33vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
}

/* Contents and nav links */
#contents-links-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1.6;
}

.content-link {
  display: flex;
  flex-direction: row;
}

#contents-links-ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

#contents-links-ul li::before {
  content: "> ";
}

#subtitle-animation {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: chartreuse;
  font-size: larger;
  font-weight: 100;
}

/* Icon button links */

#icon-button-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2em;
}

.icon-a {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon-btn {
  transition: transform 0.2s ease;
}

.icon-btn:hover .icon-svg {
  opacity: 1;
  transition: opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1); /* adjust cubic-bezier values as needed */
}

.icon-svg {
  opacity: 0.4;
  fill: var(--icon-fill-dark);
  transition: opacity 0.1s cubic-bezier(0.4, 0, 0.2, 1); /* adjust cubic-bezier values as needed */
}

.icon-svg.light-mode {
  fill: var(--icon-fill-light);
}
