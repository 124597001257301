/* All global CSS rules for tags */

:root {
  --background-color-light: #ffffff;
  --background-color-dark: #131314;
  --font-color-light: #000000;
  --font-color-dark: #ffffff;
  --font-color-subdued: #abb2ab;
  --max-body-width: 650px;
  --icon-fill-light: black;
  --icon-fill-dark: whitesmoke;
  --content-standard-gap: 1em;
  --siderail-width: 80px;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", "Roboto Mono", "sans-serif";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.6;
  width: 100%;

  /* Background colours */
  color: whitesmoke;
  background-color: #131314;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

body.light-mode {
  background-color: var(--background-color-light);
  color: var(--font-color-light);
}

button {
  cursor: pointer;
  border: 3px solid var(--background-color-light);
  background-color: var(--background-color-dark);
  color: var(--font-color-dark);
  text-align: center;
  vertical-align: middle;
  font-family: var(--root-font);
}

button.light-mode {
  border: 3px solid var(--background-color-dark);
  background-color: var(--background-color-light);
  color: var(--font-color-light);
}

a {
  color: inherit;
  text-decoration: underline;
}

pre {
  text-size-adjust: none;
}