.experience-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
}

.experience-main-content-container {
  width: 100%;
  margin: 0 1em;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: var(--content-standard-gap);
}

.experience-siderail {
  font-size: 10px;
  font-family: "Roboto Mono";
}

.experience-siderail-date {
    min-height: 28px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.experience-header-container {
  width: 100%;
  display: flex;
  gap: 1em;
}

.experience-logo-image {
  width: 68px;
  height: 68px;
  border-radius: 2px;
}

.experience-title-subtitle-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

