.project-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
  }

  .project-main-content-container {
    width: 100%;
    margin: 0 1em;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: var(--content-standard-gap);
  }

  .project-siderail {
    font-size: 10px;
    font-family: "Roboto Mono";
  }

  .project-siderail-date {
      min-height: 28px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
  }

  .project-header-container {
    width: 100%;
    display: flex;
    gap: 1em;
  }

  .project-cover-image {
    width: 163px;
    height: 68px;
    /* aspect-ratio: 2.39/1; */
    border-radius: 2px;
  }

  @media (max-width: 650px) {
    .project-header-container {
        flex-direction: column-reverse;
      }

    .project-cover-image {
      width: 100%;
      height: auto;
      aspect-ratio: 2.39/1;
    }
  }

  .project-title-subtitle-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

