#main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: var(--max-body-width);
  height: 100%;
  width: 100%;
  /* width: calc(100% - 1em); */
  box-sizing: border-box;
  overflow-x: hidden;
  font-size: 13px;
  background-color: var(--background-color-dark);
}

#main-container.light-mode {
  background-color: var(--background-color-light);
  color: var(--font-color-light);
}

/* Sections */
.section-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.section-header h1 {
  font-size: 2.2em;
  margin: 0;
  font-family: "Roboto Mono";
}

.section-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 3em 0;
  line-height: 1.6;
}

.section-content h2 {
  /* font-size: 2.2em; */
  margin: 0;
  font-family: "Roboto Mono";
}

.section-content pre {
  white-space: pre-wrap;
  margin: 0;
  line-height: 1.5em;
}

.section-content img {
  max-width: 100%;
}

.section-content-siderail {
  width: var(--siderail-width);
  min-width: var(--siderail-width);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  opacity: 0.5;
}

.siderail-vline-dotted {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20'%3E%3Ctext x='10' y='15' text-anchor='middle' font-size='16px' fill='%23FFFFFF' font-family='monospace'%3E|%3C/text%3E%3C/svg%3E");
  background-repeat: repeat-y;
  background-position: center;
  height: 5em;
}

.siderail-vline-dotted.light-mode {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20'%3E%3Ctext x='10' y='15' text-anchor='middle' font-size='16px' fill='%23000000' font-family='monospace'%3E|%3C/text%3E%3C/svg%3E");
}

.siderail-vline-bold {
  width: 4px;
  height: 100%;
  display: flex;
  justify-content: center;
  background-color: whitesmoke;
  border-radius: 2px;
}

.siderail-vline-terminator {
  font-size: 28px;
  line-height: 0.7;
  text-align: center;
}

.skills-tags, .links-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.skill-tag {
  font-family: "Roboto Mono";
  font-size: 10px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid whitesmoke;
  border-radius: 10px;
  padding: 0 8px;
}

.link-tag {
  font-family: "Roboto Mono";
  font-size: 12px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.link-icon {
 fill: whitesmoke;
 width: 12px;
 height: 12px;
 margin-right: 0.35em;
}

.subdued-font {
    color: var(--font-color-subdued);
}

/* Screen size management */

/* Page title */
#page-title {
  display: table;
  margin: 0 auto;
  font-size: 10px;
}

@media (max-width: 650px) {
  #main-container {
    max-width: 100vw;
  }

  #page-title {
    font-size: 7px;
  }

  .section-content-siderail {
    width: 60px;
    min-width: 60px;
  }
}

@media (max-width: 450px) {
  #page-title {
    font-size: 5px;
  }
}
